import PagePost from '../../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { home as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import {
    Hero,
    SEO
} from '@brandedholdings/react-components';

import Interstitial from '../../components/Interstitial';


class LenderLeadNetwork extends React.Component {
    constructor(props) {
        super(props);

        this.state = {variant: 'default'};
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    onFormSubmit = (e, fields) => {
        e.preventDefault();
        console.warn('FORM SUBMIT', fields);
        document.location.href = "/results";
    };

    render() {
        const page = this.props;

        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        return (
            <PageWrapper navigation={true}>
                <SEO post={ post } />

                <Hero
                    heading="Great News!"
                    subheading="A lender wants to work with you!"
                    actionText={ null }
                    actionLink={ "#" }
                    heroModifiers="hero--arrow"
                    actionModifiers={ null }
                />

                <div className="layout">
                    <div className="layout-content">
                        <Interstitial />
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

LenderLeadNetwork.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default LenderLeadNetwork;
